/**
 * Checks if a node/element exists
 * @param {HTMLElement|Element} node - The node/element to check
 * @returns {Boolean}
 */
function isset(node){
    if (typeof(node) != 'undefined' && node != null) {
        return true;
    }else{
        return false;
    }
}

export {isset};