// @ts-check
/**
 * @file app.js is the entry point of the project
 * @author Finlay Matheson
 */

/**
 * Imports
 */
    import { isset } from "./helpers/utils";
    import { Dropdown } from "./components/dropdown";

/**
 * Dropdowns -- Component
 */
    /* Default Dropdown Arguements */
    const dropdownArgs = {
        awaitOpenAnimation: true,
        awaitCloseAnimation: true,
    }

    /* Initialise Default Dropdowns */
    const dropdowns = document.querySelectorAll(".block-dropdown--js");
    dropdowns.forEach(function (dropdownElm) {
        new Dropdown(dropdownElm, dropdownArgs);
    });

    function handleInputChanges(){
        const guessTextInput = document.querySelector("#submit-guess-input");
        const guessSubmitBtn = document.querySelector("#submit-guess-btn");

        if(isset(guessTextInput)){
            guessTextInput.addEventListener('keydown', function(event){
                const key = event.key;
            
                switch (key) {
                    case 'Enter':
                        event.stopPropagation();
                        event.preventDefault();

                        if(isset(guessSubmitBtn)){
                            guessSubmitBtn.click();
                        }
                        break;
                    default:
                        //Do Nothing, LeBron...
                    break;
                }
            });
        }
    }
    handleInputChanges();

    document.body.addEventListener('htmx:afterSwap', function(event){
        /* Initialise Default Dropdowns */
        const dropdowns = document.querySelectorAll(".block-dropdown--js");
        dropdowns.forEach(function (dropdownElm) {
            new Dropdown(dropdownElm, dropdownArgs);
        });

        handleInputChanges();
    });
