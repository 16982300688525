/**
 * Description: 
 *
 * @class Dropdown
 * @typedef {Dropdown}
 */

class Dropdown {
    constructor(domNode, opts) {
        this.domNode = domNode;
        this.buttonNode = domNode.querySelector('button');
        this.menuNode = domNode.querySelector('[role="menu"]');

        const DEFAULTS = {
            onOpen: () => {},
            onClose: () => {}, 
            openClass: 'is-open',
            updateOnSelect: false,
            closeOnSelect: false,
            awaitCloseAnimation: false,
            awaitOpenAnimation: false
        }

        this.options = Object.assign(DEFAULTS, opts);

        this.buttonNode.addEventListener(
            'keydown',
            this.onButtonKeydown.bind(this)
        );

        this.buttonNode.addEventListener(
            'click', 
            this.onButtonClick.bind(this)
        );

        domNode.addEventListener(
            'focusin', 
            this.onFocusin.bind(this)
        );

        domNode.addEventListener(
            'focusout', 
            this.onFocusout.bind(this)
        );

        window.addEventListener(
            'mousedown',
            this.onBackgroundMousedown.bind(this),
            true
        );
    }


    /**
     * @property {Function} isOpen - Checks whether the dropdown is open
     * @returns {boolean}
     */
    isOpen() {
        return this.buttonNode.getAttribute('aria-expanded') === 'true';
    }


    /**
     * @property {Function} openDropdown - Opens the dropdown
     * @param {Object} event - The event that triggered this method
     * @returns void
     */
    openDropdown(event = null) {
        this.menuNode.setAttribute("aria-hidden", "false");
        this.domNode.classList.add(this.options.openClass);
        this.buttonNode.setAttribute('aria-expanded', 'true');

        if (this.options.awaitOpenAnimation) {
            const domNode = this.domNode;

            function handler() {
                domNode.removeEventListener(
                    'animationend', 
                    handler, 
                    false
                );
            }

            this.domNode.addEventListener(
                'animationend', 
                handler, 
                false
            );
        }

        this.options.onOpen(event, this);
    }


    /**
     * @property {Function} closeDropdown - Closes the dropdown
     * @param {Object} event - The event that triggered this method
     * @returns void
     */
    closeDropdown(event = null) {
        if (this.isOpen()) {
            this.domNode.classList.remove(this.options.openClass);
            this.buttonNode.removeAttribute('aria-expanded');

            if (this.options.awaitCloseAnimation) {
                const domNode = this.domNode;
                const menuNode = this.menuNode;
                
                function handler() {
                    menuNode.setAttribute("aria-hidden", "true");
                    domNode.removeEventListener(
                        'animationend', 
                        handler, 
                        false
                    );
                }

                this.domNode.addEventListener(
                    'animationend', 
                    handler, 
                    false
                );
            } else {
                this.menuNode.setAttribute("aria-hidden", "true");
            }

            this.options.onClose(event, this);
        }
    }


    /**
     * @property {Function} onFocusin - Handles focus of Dropdown
     * @returns void
     */
    onFocusin() {
        this.domNode.classList.add('focus');
    }


    /**
     * @property {Function} onFocusout - Handles lose of focus on Dropdown
     * @returns void
     */
    onFocusout() {
        this.domNode.classList.remove('focus');
    }


    onButtonKeydown(event) {
        const key = event.key;
        let flag = false;

        switch (key) {
            case ' ':
            case 'Enter':
            case 'ArrowDown':
            case 'Down':
                this.openDropdown(event);
                this.setFocusToFirstMenuitem();
                flag = true;
                break;

            case 'Esc':
            case 'Escape':
                this.closeDropdown(event);
                this.buttonNode.focus();
                flag = true;
                break;

            case 'Up':
            case 'ArrowUp':
                this.openDropdown(event);
                this.setFocusToLastMenuitem();
                flag = true;
                break;

            default:
                break;
        }

        if (flag) {
            event.stopPropagation();
            event.preventDefault();
        }
    }


    onButtonClick(event) {
        if (this.isOpen()) {
            this.closeDropdown(event);
            this.buttonNode.focus();
        } else {
            this.openDropdown(event);
        }

        event.stopPropagation();
        event.preventDefault();
    }


    onBackgroundMousedown(event) {
        if (!this.domNode.contains(event.target)) {
            if (this.isOpen()) {
                this.closeDropdown(event);
                this.buttonNode.focus();
            }
        }
    }
}

exports.Dropdown = Dropdown
